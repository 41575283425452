import { useStaticQuery } from "gatsby";
import React, { useState } from "react";
import Galleries from "../gallery/Galleries";
import { ongoingdata } from "../projects/commercial/OngoingData";

const ProjectList = ({ images = [], tabIndex }) => {
  return <Galleries tabIndex={tabIndex} data={images} />;
};

function ProjectStatusContent() {
  const data = useStaticQuery(graphql`
    {
      allOngoingJson(
        filter: { is_active: { eq: true } }
        sort: { order: DESC, fields: id }
      ) {
        edges {
          node {
            title
            sections {
              title
              project_updates {
                title
                images {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                videos {
                  type
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  link
                }
              }
            }
          }
        }
      }
    }
  `);

  const images = [];
  const [tabIndex, setTabIndex] = useState(0);

  console.log(data);

  data.allOngoingJson.edges.forEach(({ node }) => {
    node.sections.forEach((item) => {
      if (Array.isArray(item.project_updates)) {
        images.push({
          title: `${node.title}${item.title ? ` - ${item.title}` : ""}`,
          gallery: item.project_updates,
        });
      }
    });
  });

  return (
    <section className="residence-section">
      <div className="container">
        <div className="custom-tab-menu">
          <span
            className={`${tabIndex === 0 ? "active" : ""}`}
            onClick={() => setTabIndex(0)}
          >
            Residential
          </span>
          <span
            className={`${tabIndex === 1 ? "active" : ""}`}
            onClick={() => setTabIndex(1)}
          >
            Commercial
          </span>
        </div>
        {tabIndex === 0 ? (
          <div className="pb-4" id="synopsis">
            <h5 className="residence-section-title" id="synopsis">
              Residential Ongoing Projects
            </h5>
            <ProjectList tabIndex={tabIndex} images={images} />
          </div>
        ) : (
          <div className="pb-4" id="synopsis">
            <h5 className="residence-section-title" id="synopsis">
              Commercial Ongoing Projects
            </h5>
            <ProjectList tabIndex={tabIndex} images={ongoingdata} />
          </div>
        )}
      </div>
    </section>
  );
}

export default ProjectStatusContent;
